import React from 'react';
import Typography from '@mui/material/Typography';
import { graphql } from 'gatsby';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Layout from '../components/layout/layout';
import Markdown from '../components/markdown';

const FAQs = ({ data }) => {
  return (
    <Layout>
      <Box sx={{ mb: 8 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
          Frequently Asked Questions
        </Typography>
      </Box>

      {data.template.faqs &&
        data.template.faqs.map((faq) => (
          <Card key={faq.question} sx={{ mb: 4 }}>
            <CardContent>
              <Markdown
                source={faq.question}
                style={{
                  fontWeight: 600,
                  fontSize: '24px',
                  whiteSpace: 'normal',
                }}
              />
            </CardContent>
            <Divider />
            <CardContent>
              <Markdown
                source={faq.response}
                style={{
                  fontWeight: 400,
                  fontSize: '16px',
                }}
              />
            </CardContent>
          </Card>
        ))}
    </Layout>
  );
};

export default FAQs;

export const Head = ({ data }) => {
  const { template: { caseName = '' } } = data;

  return (
    <title>FAQ | {caseName}</title>
  )
};

export const query = graphql`
  query FaqsPage($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      caseName
      faqs {
        id
        question
        response
      }
    }
  }
`;
